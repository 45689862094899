<template>
  <div class="Authority">
    <Header title="合作客户" index="首页" titleOne="客户管理" beforeTitle="合作客户" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select v-model="companyParams.province_id" placeholder="请选择省" @change="getcity">
            <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="companyParams.city_id" placeholder="请选择市" @change="gettown">
            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="companyParams.town_id" placeholder="请选择区" @change="getstreet">
            <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="companyParams.block_id" placeholder="请选择镇/模块">
            <el-option v-for="item in street" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input
            clearable
            class="import"
            v-model="companyParams.keywords"
            placeholder="请输入企业名称/创建人关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>

          <el-button @click="goExportPartner()" class="imports">全部导出</el-button>
        </div>
        <el-button
          v-if="privilige_list.partner.includes('add')"
          class="add"
          @click="handleEdit('')"
        >新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="company.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <!-- <el-table-column prop="short_name" label="商号简称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.short_name ? scope.row.short_name : "-" }}</template>
        </el-table-column>-->
        <el-table-column prop="level" label="客户级别" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.level ? scope.row.level : "-" }}</template>
        </el-table-column>
        <el-table-column prop="business_manager" label="客户联系人" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.business_manager ? scope.row.business_manager : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="project_specialist" label="客服管家" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.project_specialist ? scope.row.project_specialist : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="is_high" label="高企" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div
              v-if="scope.row.is_high && scope.row.reassessment_time"
            >{{scope.row.reassessment_time | fmtdate}}</div>
            <div v-if="scope.row.is_high && !scope.row.reassessment_time">-</div>
            <div v-if=" !scope.row.is_high">否</div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.create_time | fmtdate }}</template>
        </el-table-column>
        <el-table-column prop="true_name" label="创建人" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.true_name ? scope.row.true_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="is_cooperation" label="合作客户" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.is_cooperation"
              :active-value="1"
              :inactive-value="2"
              active-color="#0F38FF"
              inactive-color="#CACDDF"
              @change="handleChangeStatus($event, scope.row.id)"
              :disabled="!privilige_list.partner.includes('change_cooperation')"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="230px">
          <template slot-scope="scope">
            <el-button
              v-if="privilige_list.partner.includes('edit')"
              class="edit"
              size="mini"
              @click="handleEdit(scope.row.id)"
            >编辑</el-button>
            <el-button
              v-if="privilige_list.partner.includes('view')"
              class="edit"
              size="mini"
              @click="handleDetail(scope.row.id)"
            >详情</el-button>
            <el-button
              v-if="privilige_list.partner.includes('delete')"
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="company.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="companyParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="companyParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="company.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      opt: { id: "0", name: "全部" },
      loading: true,
      labelPosition: "top",
      companyParams: {
        page: 1,
        limit: 10,
        keywords: "",
        is_cooperation: 1,
        province_id: "",
        city_id: "",
        town_id: "",
        block_id: "",
        order: "id desc",
      },
      options: [],
      province: [],
      city: [],
      town: [],
      street: [],
      privilige_list: [],//权限列表
    };
  },
  activated () {
    // this.handleSearch();
    this.companyParams
    this.$store.dispatch("getCompany", this.companyParams);
  },
  computed: {
    ...mapState(["company"]),
  },
  created () {
    this.getprovince();
    this.$store.dispatch("getCompany", this.companyParams);
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
  methods: {
    // 合作客户详情跳转
    handleDetail (id) {
      this.$router.push({ path: '/Partner/company_detail', query: { id: id, type: 'detali' } })
    },
    goExportPartner () {
      this.$router.push({
        path: "/data/data_export/go_export",
        query: { item: "partner" },
      });
    },
    handleChangeStatus ($event, id) {
      console.log($event, id);
      if ($event == 2) {
        this.$confirm('确定取消合作客户？将添加到意向客户列表', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios.post("/api/company/change_cooperation", { is_cooperation: 0, id: id, }).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            });
            this.$store.dispatch("getCompany", this.companyParams);
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
        // this.$confirm(`确定取消合作客户？将添加到意向客户列表`, "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        //   .then(async () => {
        //     this.axios.post(
        //       "/api/company/change_cooperation?id=${id}&is_cooperation=0",
        //       {
        //         is_cooperation: 0,
        //         id: id,
        //       }
        //     );
        //     this.$store.dispatch("getCompany", this.companyParams);
        //   })
        //   .catch(() => {
        //     this.$message.warning("取消操作");
        //   });
      }
    },
    handleSizeChange (val) {
      this.companyParams.limit = val;
      this.$store.dispatch("getCompany", this.companyParams);
    },
    handleCurrentChange (val) {
      this.companyParams.page = val;
      this.$store.dispatch("getCompany", { ...this.companyParams });
    },
    handleSearch () {
      this.$store.dispatch("getCompany", { ...this.companyParams, page: '', total: '' });
      this.companyParams.page = 1
    },
    handleEdit (id) {
      this.$router.push("/Partner/detail?id=" + id);
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
        this.province.unshift(this.opt);
        console.log(this.province);
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
          this.$store.dispatch("getCompany", { ...this.companyParams, page: '' });
        });
      // if (this.companyParams.province_id == "0") {
      this.companyParams.city_id = "";
      this.companyParams.town_id = "";
      this.companyParams.block_id = "";
      // }
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
          this.$store.dispatch("getCompany", { ...this.companyParams, page: '' });
        });
    },
    getstreet (id) {
      this.axios
        .get("/api/region/getBlock", {
          params: {
            town_id: id,
          },
        })
        .then((res) => {
          this.street = res.data;
          this.$store.dispatch("getCompany", { ...this.companyParams, page: '' });
        });
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/company/delete", {
            params: {
              id: id,
            },
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.$store.dispatch("getCompany", this.companyParams);
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
.imports {
  background: #edeef4;
  color: #909399;
  border: 1px solid #edeef4;
  margin: 0 10px;
}
.topUsers {
  position: relative;
}
</style>
